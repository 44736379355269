.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.flex-right {
  display: flex;
  justify-content: right;
}

.fs-1-5 {
  font-size: 1.5rem;
}
